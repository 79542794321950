import React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";

function DownloadButton({ title, href }) {
  const downloadFile = () => {
    // alert(`Downloading: ${href}`);
    window.location.href = href;
  };
  return (
    <Button variant="success" onClick={downloadFile}>
      {title}
    </Button>
  );
}

DownloadButton.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string,
};

export default DownloadButton;
