import React from "react";
import PropTypes from "prop-types";
import { line, max, format } from "d3";

function Svg({ width, height, margin, className, children }) {
  return (
    <svg width={width} height={height} className={className}>
      <g transform={`translate(${margin.left},${margin.top})`}>{children}</g>
    </svg>
  );
}

Svg.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  margin: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
};

function XAxis({ xScale, innerHeight, numTicks = 5, tickOffset = 3 }) {
  const maxValue = max(xScale.ticks(numTicks));
  const formatter = maxValue > 10000 ? format(".2s") : format("");
  return xScale.ticks(numTicks).map((tickValue) => (
    <g
      className="tick"
      key={tickValue}
      transform={`translate(${xScale(tickValue)}, 0)`}
    >
      <line y2={innerHeight} stroke="black" />
      <text
        style={{ textAnchor: "middle" }}
        dy=".71em"
        y={innerHeight + tickOffset}
      >
        {formatter(tickValue)}
      </text>
    </g>
  ));
}

XAxis.propTypes = {
  xScale: PropTypes.func,
  innerHeight: PropTypes.number,
  numTicks: PropTypes.number,
  tickOffset: PropTypes.number,
};

function YAxis({ yScale, innerWidth, numTicks = 5, tickOffset = 3 }) {
  return yScale.ticks(numTicks).map((tickValue) => (
    <g
      className="tick"
      key={tickValue}
      transform={`translate(0, ${yScale(tickValue)})`}
    >
      <line x2={innerWidth} stroke="black" />
      <text style={{ textAnchor: "end" }} dy=".32em" x={-tickOffset}>
        {tickValue}
      </text>
    </g>
  ));
}

YAxis.propTypes = {
  yScale: PropTypes.func,
  innerWidth: PropTypes.number,
  numTicks: PropTypes.number,
  tickOffset: PropTypes.number,
};

function Line({ data, xScale, yScale, xValue, yValue }) {
  const line_ = line()
    .x((d) => xScale(xValue(d)))
    .y((d) => yScale(yValue(d)));

  const d = line_(data);
  return <path className="line" d={d} />;
}

Line.propTypes = {
  data: PropTypes.array,
  xScale: PropTypes.func,
  yScale: PropTypes.func,
  xValue: PropTypes.func,
  yValue: PropTypes.func,
};

function Dot({ d, circleRadius }) {
  return <circle className="dot" cx={d.x} cy={d.y} r={circleRadius} />;
}

Dot.propTypes = {
  d: PropTypes.object,
  circleRadius: PropTypes.number,
};

function XAxisLabel({ label, x, y }) {
  return (
    <text className="x label" textAnchor="middle" x={x} y={y}>
      {label}
    </text>
  );
}

XAxisLabel.propTypes = {
  label: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
};

function YAxisLabel({ label, x, y }) {
  return (
    <text
      className="y label"
      textAnchor="middle"
      x={x}
      y={y}
      transform={`rotate(-90,${x},${y})`}
    >
      {label}
    </text>
  );
}

YAxisLabel.propTypes = {
  label: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
};

export { Svg, XAxis, YAxis, Line, Dot, XAxisLabel, YAxisLabel };
